import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button, Input } from "../../components";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Header1 from "../../components/Header1";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the datepicker styles


// FormField Component
const FormField = ({ label, placeholder, name, value, onChange, error }) => (
  <div className="mr-1.5 mt-2.5 flex w-[100%] flex-col items-start gap-3.5">
    <Text as="p">{label}</Text>
    <Input
      shape="round"
      name={name}
      placeholder={placeholder}
      className="w-[100%] !text-black-900_01"
      value={value}
      onChange={onChange}
      
    />
    {error && <p style={{ color: 'red' }}>{error}</p>}
  </div>
);
const formatDate = (newdate) => {
  let date=new Date(newdate);
  if (!date) return '';
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  console.log(`${month}/${day}/${year}`);
  return `${month}/${day}/${year}`;
  
};
// FormField Component
const DateFormField = ({ label, placeholder, name, value, onChange, error, children }) => (
  <div className="mr-1.5 mt-2.5 flex w-[100%] flex-col items-start gap-3.5">
    <Text as="p">{label}</Text>
    {children ? (
      children
    ) : (
      <Input
        shape="round"
        name={name}
        placeholder={placeholder}
        className="w-[100%] !text-black-900_01"
        value={value}
        onChange={onChange}
      />
    )}
    {error && <p style={{ color: 'red' }}>{error}</p>}
  </div>
);

// Main Component
export default function PersonalInformationPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Country: '',
    Nationality: '',
    FirstName: '',
    LastName: '',
    MiddleName: '',
    DOB: new Date(),
    Address: '',
    State: '',
    City: '',
    ZipCode: '',
    PassportNumber: '',
    NationalID: '',
    DriversLicense: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  // Example token, replace with actual token fetching logic
  const token = localStorage.getItem("authToken"); // Get the token from localStorage or another source

  // Fetch user login details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("https://quickall.io:3001/login", {
          headers: {
            Authorization: 'Bearer ' + token, // Pass the Bearer token in the Authorization header
          },
        });
        if (response.data && response.data.user) {
          setUsername(response.data.user); // Set the username if available
          const responseData = await axios.get("https://quickall.io:3001/personal-details", {
            headers: {
              Authorization: 'Bearer ' + token, // Pass the Bearer token in the Authorization header
            },
          });
          if(responseData.data && responseData.data.personaldetails){
            setFormData({
              Country: responseData.data.personaldetails.country,
              Nationality: responseData.data.personaldetails.nationality,
              FirstName: responseData.data.personaldetails.first_name,
              LastName: responseData.data.personaldetails.last_name,
              MiddleName: responseData.data.personaldetails.middle_name,
              DOB: new Date(responseData.data.personaldetails.dob),
              Address: responseData.data.personaldetails.address,
              State: responseData.data.personaldetails.state,
              City: responseData.data.personaldetails.city,
              ZipCode: responseData.data.personaldetails.zip_code,
              PassportNumber: responseData.data.personaldetails.passport_number,
              NationalId: responseData.data.personaldetails.national_id,
              DriversLicense: responseData.data.personaldetails.drivers_license,
            });
            console.log(formData);
          }
        }
      } catch (error) {
        console.error("Error fetching user details", error);
        // Handle error appropriately (e.g., redirect to login page or show error message)
      }
    };

    if (token) {
      fetchUserDetails(); // Call API only if token exists
    } else {
      console.error("No token found, redirecting to login...");
      // Optionally redirect to login page if token is missing
    }
  }, [token]);


  // Handle input changes
  const handleInputChange = (e, nameValue) => {
    setFormData({
      ...formData,
      [nameValue]: e,
    });
  };

   // Handle date changes
   const handleDateChange = (date) => {
    console.log(date);
    setFormData({
      ...formData,
      DOB: new Date(date),
    });
  };
  // Validate form fields
  const validate = () => {
    const errors = {};
    if (!formData.Country) errors.Country = 'Country is required';
    if (!formData.Nationality) errors.Nationality = 'Nationality is required';
    if (!formData.FirstName) errors.FirstName = 'First Name is required';
    if (!formData.LastName) errors.LastName = 'Last Name is required';
    if (!formData.DOB) errors.DOB = 'Date of Birth is required';
    if (!formData.Address) errors.Address = 'Address is required';
    if (!formData.State) errors.State = 'State is required';
    if (!formData.City) errors.City = 'City is required';
    if (!formData.ZipCode) errors.ZipCode = 'Zip/Postal Code is required';

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission (with validation and Axios call)
  const handleSubmit = async () => {
    if (!validate()) return; // Stop submission if form is invalid

    setLoading(true);
    try {
      const token = localStorage.getItem("authToken"); // Get the token from localStorage or another source
      const response = await axios.post('https://quickall.io:3001/personal-details', formData, {
        headers: {
          'Authorization': 'Bearer ' + token,  // Replace YOUR_ACCESS_TOKEN with your actual token
          'Content-Type': 'application/json'
        }});
      console.log('Form submitted successfully:', response.data);
      navigate('/plans1');
      // Navigate to next page or show success message here
    } catch (error) {
      console.error('Error submitting form:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Personal Details - Update Your Information</title>
        <meta name="description" content="Update your personal information including name, address, and contact details." />
      </Helmet>
      <Header1 className="self-stretch" username={username}  />
      <div className="pt-20 sm:pt-4 md:pt-28 flex items-start bg-white-A700 justify-between pl-[110px] md:p-5 gap-5 md:flex-col">
        <div className="flex w-[39%] flex-col items-start md:w-full">
          <div className="mt-[130px] flex items-center justify-between gap-5">
            <Text size="lg" as="p" className="!text-black-900 bold" style={{ fontSize: "30px" }}>
              <b>Personal details</b>
            </Text>
          </div>
          <Text size="xs" as="p" className="mobile w-full leading-7 !text-black-900_99">
            Enter your personal details.
          </Text>

          <div className="mt-[51px] flex flex-col items-end self-stretch">
            <FormField
              label="Country*"
              placeholder="Select Country"
              name="Country"
              value={formData.Country}
              onChange={(e) => handleInputChange(e, "Country")}
              error={errors.Country}
              required
            />
            <FormField
              label="Nationality (optional)"
              placeholder="Enter Nationality"
              name="Nationality"
              value={formData.Nationality}
              onChange={(e) => handleInputChange(e, "Nationality")}
              error={errors.Nationality}
            />
            <FormField
              label="First Name*"
              placeholder="Enter First Name"
              name="FirstName"
              value={formData.FirstName}
              onChange={(e) => handleInputChange(e, "FirstName")}
              error={errors.FirstName}
              required
            />
            <FormField
              label="Last Name*"
              placeholder="Enter Last Name"
              name="LastName"
              value={formData.LastName}
              onChange={(e) => handleInputChange(e, "LastName")}
              error={errors.LastName}
              required
            />
            <FormField
              label="Middle Name (optional)"
              placeholder="Enter middle name"
              name="MiddleName"
              value={formData.MiddleName}
              onChange={(e) => handleInputChange(e, "MiddleName")}
            />
            <DateFormField
              label="DOB*"
              name="DOB"
              error={errors.DOB}
              required
            >
              <DatePicker
                selected={formData.DOB}
                onChange={handleDateChange}
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                style={{paddingLeft: "33px"}}
                
              />
            </DateFormField>
            <FormField
              label="Address*"
              placeholder="Enter residential address"
              name="Address"
              value={formData.Address}
              onChange={(e) => handleInputChange(e, "Address")}
              error={errors.Address}
              required
            />
            <FormField
              label="State*"
              placeholder="Select State"
              name="State"
              value={formData.State}
              onChange={(e) => handleInputChange(e, "State")}
              error={errors.State}
              required
            />
            <FormField
              label="City*"
              placeholder="Select City"
              name="City"
              value={formData.City}
              onChange={(e) => handleInputChange(e, "City")}
              error={errors.City}
              required
            />
            <FormField
              label="Zip/Postal Code*"
              placeholder="Enter code"
              name="ZipCode"
              value={formData.ZipCode}
              onChange={(e) => handleInputChange(e, "ZipCode")}
              error={errors.ZipCode}
              required
            />

            <div className="w-full mt-[29px] flex justify-around">
              <Button
                shape="round"
                className="min-w-[170px] sm:px-5 skip-button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Continue'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
