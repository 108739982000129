import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function Disclaimer() {
  return (
    <>
            <Helmet>
                <title>QUICKALL</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>

        <div className="flex w-full flex-col items-center gap-5 bg-white-A700 md:gap-24 sm:gap-16">
            <Header className="self-stretch" />
            <div className="pt-20 md:pt-28 container-sm pl-[75px] pr-[75px] md:p-5 md:px-5">
                <div className="flex flex-col items-center gap-[25px] sm:gap-[33px]">
                    <div className="flex w-[67%] flex-col items-center gap-[7px] md:w-full"></div>
        
                        <Heading as="h1"> Website Disclaimer </Heading>
          
                        <Text as="p" className="w-full text-center !font-normal  "><strong>Most recent revision: June 20th, 2024</strong></Text>
                        <Text as="p" className="mt-3 w-full !font-normal leading-7"><span  >The information provided by <strong> QUICKALL FINANCE INC. </strong> (“we”, “us” or “ours”) on www.quickall.com(the "Site") and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</span></Text>
                </div>
            </div>

                    <Footer className="self-stretch" />
        </div>   
    
    </>
  );
}