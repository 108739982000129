import React from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const features = [
  {
    heading: "Smart Fragmented Nodes (SFN)",
    text: "Intelligent Fragmented Nodes are nimble, low-power apparatuses engineered to execute intricate computations using minimal input data. These nodes function as the initial stage of transaction processing within the QuickAll network. Operating around-the-clock, SFNs establish a mesh network to guarantee streamlined transaction validation. Furthermore, these nodes enhance network security through the early detection and eradication of malicious transactions. In exchange for their active involvement, SFNs are rewarded for each transaction that they successfully process."
  },
  {
    heading: "Smart Observer Nodes (SON)",
    text: "Smart Observer Nodes serve as the mechanism for oversight on the QuickAll blockchain. Five SFNs are selected at random and five are geo-location-based for each transaction by D-AFI. The quickest SFN processes the transaction, whereas the remaining nine nodes function as observer nodes to verify the transaction's authenticity. This guarantees an elevated degree of security and reliability, given that the system operates autonomously and is not dependent on a solitary master node. The integrity of the network is preserved through the verification of transactions and the prevention of fraudulent activities by SONs."
  },
  {
    heading: "Smart Neural Nodes (SNN)",
    text: "Full nodes, which contain an exact replica of the blockchain ledger, are referred to as Smart Neural Nodes. Their primary function is to ensure consensus throughout the network. Two distinct varieties are available: Validators (SNN-v) and Block Creators (SNN-b). Before transactions are appended to the blockchain, SNN-v nodes verify their adherence to the network's regulations and avert any potential fraudulent activities. Utilizing high-end hardware configurations, SNN-b nodes are responsible for block formation, ensuring efficient traffic management and block formation. To deter malicious activity, both varieties of SNNs adhere to Know Your Customer (KYC) requirements and are motivated by their performance as well as Smart Reputation Scores (SRS)."
  }
];

export default function FeaturesPage() {
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="w-full bg-white-A700">
        <div className="flex flex-col items-center justify-center gap-[35px] bg-white-A700 pb-[80px] md:gap-[20px] md:pb-5 sm:gap-[10px]">
          <Header className="self-stretch" />
          <div className="pt-20 md:pt-28 container-sm flex items-center justify-between gap-5 pl-[9px] pr-2.5 md:flex-col md:p-5">
            <div className="flex w-[45%] flex-col items-start gap-8 md:w-full">
              <Heading
                size="lg"
                as="h2"
                className="w- full sm:leading-10 md:leading-[45px] leading-[74px] !text-black-900 md:w-full"
              >
                Unmatched Blockchain Features
              </Heading>
              <Text as="p" className="w-full leading-7 !text-black-900_b2">
              Quickall provides unparalleled blockchain functionalities propelled by 
              state-of-the-art advancements. The decentralized, AI-powered platform we employ 
              guarantees dependable security, smooth scalability, and effective automation. 
              Explore the ways in which our cutting-edge technology can revolutionize your 
              company's operations and propel expansion with unmatched dependability and efficacy.
              </Text>
              
            </div>
            <Img
              src="images/hero.svg"
              alt="featurehero"
              className="h-[409px] w-[51%] md:w-full md:mt-[-60px]"
            />
          </div>
        </div>
        <div className="mt-[34px] sm:mt-[10px]">
          <div className="flex flex-col items-center">
            <div className="container-sm flex flex-col items-center gap-12 md:p-5">
              <Heading
                as="h2"
                className="w-[49%] text-center leading-[64px] md:w-full md:mt-[-120px] text-lg sm:text-2xl"
              >
                Next-Gen Blockchain Innovations
              </Heading>
             
             
            <div className="flex gap-8 self-stretch md:flex-col">
              {features.map((feature, index) => (
                <div
                  key={`feature${index}`}
                  className="flex w-full flex-col items-start justify-top gap-[13px] bg-gray-900_0c pb-12 pl-[47px] pr-8 pt-[50px] md:py-5 md:pl-5 sm:p-5"
        >
                   <Text
                    size="lg"
                    as="p"
                    className="!font-normal !text-blue_gray-900_01"
                    >
                    {feature.heading}
                  </Text>
                  <Text as="p" className="w-full !font-normal leading-7">
                    {feature.text}
                  </Text>
                </div>
              ))}
            </div>


            </div>
            <div className="container-sm mt-[63px] flex items-start justify-between gap-5 md:flex-col md:p-5">
              <div className="flex w-[49%] flex-col items-start gap-9 md:w-full">
                <Text size="2xl" as="p" className="!text-gray-900_03">
                  Enhanced Security
                </Text>
                <Text
                  as="p"
                  className="w-full !font-normal leading-7 !text-blue_gray-900_99_01"
                >
                  Equipped with quantum-resistant cryptography and AI-driven algorithms, 
                  Quickall's Enhanced Security safeguards the blockchain against ever-evolving 
                  cyber threats. Quickall employs Decentralized Artificial Fragmentary Intelligence 
                  (D-AFI) to safeguard against malicious activities by ensuring real-time threat 
                  detection and robust node verification. In addition to validating transactions, 
                  the two-layer verification system provides additional security layers. 
                  By integrating sophisticated technologies, the network is strengthened against
                  malicious attacks and guarantees the integrity and immutability of all data and
                  transactions; consequently, this bolsters confidence in the system as a whole.
                </Text>
              </div>
              <Img
                src="images/img_card.svg"
                alt="imageseventeen"
                className="h-auto w-[45%] object-contain md:w-full"
              />
            </div>
            <div className="mt-[70px] flex justify-center self-stretch bg-gray-900_0c pb-32 pt-[117px] md:py-5">
              <div className="container-xs flex items-start justify-between gap-5 md:flex-col md:p-5">
                <Img
                  src="images/img_image26.svg"
                  alt="imageeighteen"
                  className="visible md:hidden mt-2.5 h-auto w-[45%] object-contain md:w-full"
                />
                <div className="flex w-[49%] flex-col items-start gap-[77px] md:w-full md:gap-[30px] sm:gap-[38px] sm:mt-[-80px]">
                  <Text size="2xl" as="p" className="!text-gray-900_03">
                    Versatile Applications
                  </Text>
                  <Img
                    src="images/img_image26.svg"
                    alt="imageeighteen"
                    className="xl:hidden lg:hidden md:visible mt-2.5 h-auto w-[45%] object-contain md:w-full"
                  />
                  <Text
                    as="p"
                    className="w-full !font-normal leading-7 !text-blue_gray-900_99"
                  >
                    Quickall's blockchain technology facilitates a wide range of applications in 
                    numerous industries by leveraging decentralized solutions and artificial 
                    intelligence to address the diverse requirements. It improves transaction 
                    efficiency and security in finance. It assures the protection of patient 
                    information and personalized medicine in the healthcare industry. 
                    Transparent and real-time monitoring is advantageous for the supply chain 
                    industry, whereas the automotive sector experiences enhancements in fleet 
                    management and automated procedures. In addition to supporting government and 
                    insurance applications, Quickall's platform provides innovative, scalable, and 
                    robust solutions that are customized to address the specific challenges and 
                    demands of each industry.
                  </Text>
                </div>
              </div>
            </div>
            {/* <div className="relative h-[1180px] self-stretch"> */}
            <div className=" left-0 right-0 top-[0.00px] m-auto flex w-full justify-center bg-white-A700 pb-32 pt-[126px] md:py-5">
              <div className="container-sm flex items-start justify-between gap-5 pl-[18px] md:flex-col md:p-5">
                <div className="flex w-[49%] flex-col items-start gap-[81px] md:w-full md:gap-[30px] sm:gap-10">
                  <Text size="2xl" as="p" className="!text-gray-900_03">
                    Decentralized AI Framework
                  </Text>
                  <Img
                    src="images/img_image25.png"
                    alt="imagenineteen"
                    className="xl:hidden lg:hidden md:visible h-auto w-[45%] object-contain md:w-full"
                  />

                  <Text
                    as="p"
                    className="w-full !font-normal leading-7 !text-blue_gray-900_99"
                  >
                    Blockchain technology is transformed by the Decentralized AI Framework of 
                    Quickall, which distributes intelligence across numerous nodes. This methodology
                    guarantees impartial, automated decision-making devoid of any human involvement,
                    thereby augmenting the confidence and effectiveness of transactions. 
                    The Decentralized Artificial Fragmentary Intelligence (D-AFI) system optimizes 
                    transaction processing and block size in response to network demands. Quickall 
                    prevents malicious activities, detects hazards in real-time, and enhances 
                    security through the integration of AI. In addition to enhancing performance 
                    and scalability, this framework guarantees a transparent, self-regulating, and
                    robust blockchain ecosystem.
                  </Text>
                </div>
                <Img
                  src="images/img_image25.png"
                  alt="imagenineteen"
                  className="visible md:hidden h-auto w-[45%] object-contain md:w-full"
                />
              </div>
            </div>
            <div className=" bottom-[0.00px] left-0 right-0 m-auto flex w-full justify-center bg-amber-200_19 pb-32 pt-[126px] md:py-5">
              <div className="container-sm flex items-start justify-between gap-5 pr-[15px] md:flex-col md:p-5">
                <Img
                  src="images/img_image20.svg"
                  alt="imagetwenty"
                  className="visible md:hidden h-auto w-[45%] object-contain md:w-full"
                />
                <div className="flex h-full w-[49%] flex-col items-start gap-[4px] md:w-full md:gap-[30px] sm:gap-1 pb-[0px]">
                  <Text size="2xl" as="p" className="!text-gray-900_03">
                    Innovative Consensus Mechanism
                  </Text>
                  <Img
                    src="images/img_image20.svg"
                    alt="imagetwenty"
                    className="xl:hidden lg:hidden md:visible h-auto w-[45%] object-contain md:w-full"
                  />
                  <Text
                    as="p"
                    className="w-full !font-normal leading-7 !text-blue_gray-900_99"
                  >
                    To maximize blockchain performance, Quickall's Innovative Consensus Mechanism, 
                    Proof of Machine Trust (PoMT), combines AI, Proof of Stake, and Proof of 
                    Reputation. This hybrid strategy maintains decentralization and high security 
                    standards while ensuring rapid, effective transaction validation. By employing 
                    AI-powered algorithms to choose validator nodes according to their historical 
                    performance and reputation scores, the risk of centralization is mitigated and 
                    trust is increased. By minimizing energy consumption, enhancing scalability, and
                     ensuring a robust, transparent, and equitable consensus process, PoMT's 
                     innovative design establishes new benchmarks for blockchain technology.
                  </Text>
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>
        <div className="mt-[-80px]">
        <Footer />
        </div>
      </div>
    </>
  );
}
