import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header1 from "../../components/Header1";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Plans1Page() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [username, setUsername] = useState("");
  const [isagreementAccepted, setIsagreementAccepted] = useState(false);
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("https://quickall.io:3001/login", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        if (response.data && response.data.user) {
          setUsername(response.data.user);
          setIsagreementAccepted(response.data.agreementAccepted ? true : false);
        }
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    };

    if (token) {
      fetchUserDetails();
    } else {
      console.error("No token found, redirecting to login...");
    }
  }, [token]);

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const validate = () => {
    if(!isagreementAccepted){
      setError('Please accept aggrement by going to agreement screen.');
      navigate('/useragreement1',{ state: { amount: amount } }); // Redirect to the agreement page
      return false;
    }
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      setError('Please enter a valid amount greater than zero.');
      return false;
    }
    // if (Number(amount) < 500) {
    //   setError('Amount must be greater than $500.');
    //   return false;
    // }
    setError('');
    return true;
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (!validate()) return;

    // Navigate to payment page with amount as state
    navigate('/checkout', { state: { amount: amount } });
  };

  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      
      <div className="flex w-full flex-col items-center bg-white-A700">
        <Header1 className="self-stretch" username={username}/>

        {/* Main Section */}
        <div className="pt-[100px] sm:pt-15 md:pt-28 container-md mt-4 flex justify-center p-5 md:p-10">
          <div className="flex w-full flex-col items-center gap-10">

            {/* Page Title */}
            <div className="flex w-full flex-col items-center gap-4 md:w-full text-center">
              <Heading as="h1" className="text-black-900 text-3xl sm:text-2xl md:text-2xl">
                Barter Certificate Plans
              </Heading>
              <Text as="p" className="font-normal leading-7">
                Quickall's Barter certificate plans are designed for everyone. 
                Select a suitable plan to become a Money Manager and participate in secure, 
                efficient trading.
              </Text>
            </div>

            {/* Plan Cards Section */}
            <div className="flex flex-wrap gap-10 justify-center items-center w-full">
              
              {/* Standard Plan */}
              <PlanCard
                title="Standard"
                priceRange="$500 - $10000"
                description="You will get equivalent value of GGQ Coins after 6 months."
                bgGradient="bg-gradient5"
                textColor="#ffffff"
              />
              
              {/* Premium Plan */}
              <PlanCard
                title="Premium"
                priceRange="$10001 - $20000"
                description="You will get equivalent value of GGQ Coins after 6 months and a bonus of 5%."
                bgGradient="bg-gradient2"
                textColor="#000000"
              />

              {/* Pro Plan */}
              <PlanCard
                title="Pro"
                priceRange="$20001 and above"
                description="You will get equivalent value of GGQ Coins after 6 months and a bonus of 10%."
                bgGradient="bg-gradient4"
                textColor="#ffffff"
              />
            </div>
          </div>
        </div>
        <form className="CreateaccountPage flex items-center gap-2 mt-[-10px] sm:mt-[20px]">
          <input
            className="h-14 custom-border"
            type="number"
            name="amount"
            placeholder="Enter amount"
            value={amount}
            onChange={handleInputChange}
          />
          <Button
            color="teal_900"
            onClick={handleClick}
            className="mt-[10px] w-[100%] border-2 border-solid rounded-full border-white-A700 font-medium md:ml-0 sm:px-5"
          >
            Buy
          </Button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
        <Footer className="mt-[173px] self-stretch" />
      </div>
    </>
  );
}

        function PlanCard({ title, priceRange, description, bgGradient, textColor}) {
          return (
            <div className={`relative flex flex-col items-center w-[30%] md:w-full rounded-lg ${bgGradient} border-[3px] border-yellow-500 shadow-lg pb-12 pr-14 pl-14 pt-4 md:pb-5 md:pl-5 sm:min-h-[400px] min-h-[300px]`}>
              
              <Text as="h4" className=" text-4xl text-shadow-ts text-center" style={{ color: textColor }}>
                {title}
              </Text>
              <Heading as="h6" className="mt-6 text-[18px] text-center sm:text-2xl" style={{ color: textColor }}>
                {priceRange}
              </Heading>
              <Text as="p" className="mt-4 w-full font-normal leading-7 text-left" style={{ color: textColor }}>
                {description}
              </Text>
            </div>
          );
        }