import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Header1 from "components/Header1";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";

const stripePromise = loadStripe("pk_live_51Q13FqRwqXFlW2QyPRUua9LtK19VY1C7qLCbbVbivCwkDc4Dhjvq4isZbqTSY4fhmpUeDoEHQOMphgBDCvgVmBEW00DuWnMp1R");

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://quickall.io/userdashboard",
      },
    });

    if (result.error) {
      setMessage(result.error.message);
    } else {
      setMessage("Payment processed successfully.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button style={{
        backgroundColor: !stripe ? "#d3d3d3" : "#007BFF", // Gray when disabled, blue when enabled
        marginTop: "20px", // Adjust the value as needed
        color: "#ffffff",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: !stripe ? "not-allowed" : "pointer", // Change cursor to 'not-allowed' when disabled
        border: "none",
        borderRadius: "5px",
        opacity: !stripe ? 0.6 : 1, // Make the button semi-transparent when disabled
      }} disabled={!stripe}>Submit</button>
      {message && <div>{message}</div>}
    </form>
  );
};

const InjectedCheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation(); // Retrieve state passed from navigate
  const { amount } = location.state || {}; // Get the amount
  const token = localStorage.getItem("authToken");
  const [username, setUsername] = useState("");
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("https://quickall.io:3001/login", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (response.data && response.data.user) {
          setUsername(response.data.user);
        }
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    };

    if (token) {
      fetchUserDetails();
    } else {
      console.error("No token found, redirecting to login...");
    }
  }, [token]);
  useEffect(() => {
    if (!amount) return; // Exit if amount is not defined

    const token = localStorage.getItem("authToken");
    axios.post("https://quickall.io:3001/process-payment", { amount: amount }, { // Amount in cents
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        setClientSecret(response.data.client_secret);
      })
      .catch((error) => {
        console.error("Error fetching client secret:", error);
      });
  }, [amount]);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    clientSecret && (
      <>
        <Helmet>
          <title>QUICKALL</title>
          <meta name="description" content="Web site created using create-react-app" />
        </Helmet>
        <div className="flex w-full flex-col items-center gap-5 bg-white-A700 md:gap-24 sm:gap-16">
        <Header1 className="self-stretch" username={username} />
          <div className="pt-20 sm:pt-10 md:pt-28 container-sm pl-[75px] pr-[75px] md:p-5 md:px-5">
            <div className="flex flex-col items-center gap-[25px] sm:gap-[33px]">
              <div className="flex w-[67%] flex-col items-center gap-[7px] md:w-full"></div>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  );
};

export default InjectedCheckoutForm;
