import React, { useState, useCallback } from "react";
import { Drawer, Menu } from "react-daisyui";
import { Link, useNavigate } from "react-router-dom";
import { Button, Text, Heading, Img } from "./..";
import MenuIcon from "../../assets/images/menu.png";

export default function Header({ ...props }) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const toggleVisible = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  return (
    <>
      <header
        {...props}
        className={`z-50 fixed top-0 left-0 flex w-full justify-center items-center bg-[#5f2c0f]`}
        style={{ height: '110px' }}
      >
        <div className="flex md:w-full justify-center items-center md:justify-between container-xs py-5 px-2">
          
            <div className="flex sm:w-[30%] sm:h-[30%] md:w-[40%] lg:w-[70%] justify-center items-center ">
            <Link to={"/home1"} className="flex items-center space-x-2">
              <Img
                src="images/logo_transperent.svg"
                alt="logo image"
                className="h-[66px] w-[65px] sm:h-[50px] sm:w-[50px] items-start object-cover sm:ml-[30px]"
              
              />
            
              <Heading
                size="s"
                as="h3"
                className="pl-2 h-full !text-white-A700 sm:text-[24px]"
              >
              
                QUICKALL
              </Heading>
            </Link>
            </div>
          
            <Link to={"/bannergpage"}>
              <Img
                src="images/Live.svg"
                alt="logo image"
                className="h-[60px] w-[200px] object-cover sm:hidden md:block"
              />
               </Link>
          <ul className="sm:hidden md:hidden flex flex-1 flex-row gap-6 lg:px-4 xl:pr-20">
            
            <li>
              <Text as="p" className="cursor-pointer">
                <Link
                  to="/home1"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Home
                </Link>
              </Text>
            </li>
            <li>
              <Text as="p" className="cursor-pointer">
                <Link
                  to="/aboutus"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  About Us
                </Link>
              </Text>
            </li>
            <li>
              <Text
                as="p"
                className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
              >
                <Link
                  to="/features"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Features
                </Link>
              </Text>
            </li>
            <li>
              <Text
                as="p"
                className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
              >
                <Link
                  to="/copyrightcertificate"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Doc
                </Link>
              </Text>
            </li>
            <li>
              <Text
                as="p"
                className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
              >
                <Link
                  to="/blogpage"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Blog
                </Link>
              </Text>
            </li>
            <li>
              <Text
                as="p"
                className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
              >
                <Link
                  to="/contactus"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </Link>
              </Text>
            </li>
            <li>
              <Text
                as="p"
                className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
              >
                <Link
                  to="/login"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Login
                </Link>
              </Text>
            </li>
          </ul>
          
          <Button
            color="teal_900"
            onClick={() => {
              navigate("/plans");
            }}
            className="sm:hidden md:hidden w-[25%] border-2 border-solid rounded-full border-white-A700 font-medium md:ml-0 sm:px-5"
          >
            Barter Certificate
          </Button>
          <div className="visible lg:hidden flex justify-end sm:w-1/2 items-center p-2">
            <img
              className="cursor-pointer sm:h-8 sm:w-8"
              onClick={toggleVisible}
              src={MenuIcon}
              alt="..."
              height={60}
              width={60}
            />
          </div>
        </div>
      </header>
      <Drawer
        className="w-full h-full z-[49]"
        open={visible}
        onClickOverlay={toggleVisible}
        side={
          <Menu className="w-80 h-full text-base-content bg-[#fff]/100 p-0">
            <div className="flex w-full items-center justify-start gap-1 pt-24 pl-8 pb-2">
              
              
           
            {/* <hr  className="w-full border-bottom-1 border-gray-300 mt-3"/> */}
            </div>
            <ul className="w-full flex flex-col gap-1 pl-8 pr-3">
              <li>
                <a href="/home1">
                  <Text as="p" className="text-black">
                    Home
                  </Text>
                </a>
              </li>
              <li>
                <a href="aboutus">
                  <Text as="p" className="text-black">
                    About us
                  </Text>
                </a>
              </li>
              <li>
                <a href="/features">
                  <Text as="p" className="text-black">
                    Features
                  </Text>
                </a>
              </li>
              <li>
                <a href="/blogpage">
                  <Text as="p" className="text-black">
                    Blog
                  </Text>
                </a>
              </li>
              <li>
                <a href="copyrightcertificate">
                  <Text as="p" className="text-black">
                    Doc
                  </Text>
                </a>
              </li>
              <li>
                <a href="/plans">
                  <Text as="p" className="text-black">
                    Barter Certificate
                  </Text>
                </a>
              </li>
              <li>
                <a href="/login">
                  <Text as="p" className="text-black">
                    Login
                  </Text>
                </a>
              </li>
            </ul>
            <Link to={"/bannergpage"}>
              <Img
                src="images/Live.svg"
                alt="logo image"
                className="h-[60px] w-[200px] object-cover"
              />
               </Link>
          
          </Menu>
        }
      ></Drawer>
    </>
  );
}
