import React, { useState, useCallback } from "react";
import { Drawer, Menu } from "react-daisyui";
import { Link, useNavigate } from "react-router-dom";
import { Button, Text, Heading, Img } from "..";
import MenuIcon from "../../assets/images/menu.png";

export default function Header1({ username, ...props }) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  

  const toggleVisible = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  // Logout function to remove token and redirect to login
  const handleLogout = () => {
    // Remove token from localStorage or sessionStorage
    localStorage.removeItem("authToken"); // Adjust if using sessionStorage

    // Redirect to login page after logout
    navigate("/login");
  };

  return (
    <>
      <header
        {...props}
        className="z-50 fixed top-0 left-0 flex w-full justify-center items-center bg-[#5f2c0f]"
      >
        <div className="flex md:w-full justify-center items-center md:justify-between container-xs py-5 px-2">
          <div className="flex md:w-[40%] lg:w-[50%] items-center justify-start">
            <Link to={"/home1"}>
              <Img
                src="images/logo_transperent.svg"
                alt="logo image"
                className="h-[65px] w-[65px] object-cover"
              />
            </Link>
            <Heading size="s" as="h3" className="pl-2 h-full !text-white-A700">
              QUICKALL
            </Heading>
          </div>

          <ul className="sm:hidden md:hidden flex flex-1 flex-row gap-6 lg:px-4 xl:pr-20 items-center">
            <li>
          <Text as="p" className="cursor-pointer">
                <Link
                  to="/userdashboard"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Dashboard
                </Link>
              </Text>
            </li>  
            
            
            <li>
              <Text as="p" className="cursor-pointer">
                <Link
                  to="/personalinformation"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Buy Certificate
                </Link>
              </Text>
            </li>
            <li>
              <Text as="p" className="cursor-pointer">
                <Link
                  to="/useragreement1"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Agreement
                </Link>
              </Text>
            </li>
            <li>
              <Text as="p" className="cursor-pointer">
                <Link
                  to="#"
                  className="text-[#b2b2ca] hover:!text-white-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  {username || "Username"}
                </Link>
              </Text>
            </li>
            <li>
              <Button
                className="text-[#b2b2ca] hover:!text-white-A700 h-full flex items-center"
                onClick={handleLogout}
                style={{ border: "none", background: "none", padding: 0 }}
              >
                Logout
              </Button>
            </li>
          </ul>

          <div className="visible lg:hidden flex justify-end sm:w-1/2 items-center p-2">
            <img
              className="cursor-pointer"
              onClick={toggleVisible}
              src={MenuIcon}
              alt="menu icon"
              height={50}
              width={50}
            />
          </div>
        </div>
      </header>

      <Drawer
        className="w-full h-full z-[49]"
        open={visible}
        onClickOverlay={toggleVisible}
        side={
          <Menu className="w-80 h-full text-base-content bg-[#fff]/100 p-0">
            <div className="flex w-full items-center justify-start gap-1 pt-24 pl-8 pb-2">
            
            </div>
            <ul className="w-full flex flex-col gap-1 pl-8 pr-3">
            <li>
                <Link to="/userdashboard">
                  <Text as="p" className="text-black">Dashboard</Text>
                </Link>
              </li>
              <li>
                <Link to="/personalinformation">
                  <Text as="p" className="text-black">Buy Certificate</Text>
                </Link>
              </li>
              <li>
                <Link to="/useragreement1">
                  <Text as="p" className="text-black">Agreement</Text>
                </Link>
              </li>
              <li>
                <Link to="/home1">
                  <Text as="p" className="text-black">Logout</Text>
                </Link>
              </li>
              
            </ul>
            
          </Menu>
        }
      ></Drawer>
    </>
  );
}
